$primary-color: #3626a7;
$secondary-color: #caff8a;
$bg-color: #f5f5f5;


.contact {
  background-color: $bg-color;

  h2 {
    font-size: 4rem;
    font-weight: bold;
    color: $primary-color;
  }

  font-size: 1.5rem;

  .input-labels {
    color: #3626A7 !important;
  }
}

.contact-div {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .form-span {
    background-color: white;
    z-index: 1;
    border-radius: 20px;
  }

  form {
    background-color: transparent;
  }
}

.contact-div::after {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  background-image: conic-gradient(transparent, transparent, transparent, $primary-color);
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}
.contact-div::before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  background-image: conic-gradient(transparent, transparent, transparent, $secondary-color);
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
