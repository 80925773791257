$primary-color: #3626a7;
$secondary-color: #caff8a;
$bg-color: #f5f5f5;

.projects {
  background-color: $bg-color;

  h2 {
    font-size: 4rem;
    font-weight: bold;
    color: $primary-color;
  }

  h3 {
    color: $primary-color;
  }

  span {
    text-transform: uppercase;
    color: $primary-color;
  }

  .project-container {
    background-color: white;
    height: fit-content;
  }

  a {
    color: $primary-color;
    margin-right: 1rem;
    text-decoration: none;
    font-weight: bold;
    display: inline-flex;
  }

  .icon {
    margin-left: 0.5rem;
  }

  img {
    object-fit: cover;
    height: 100%;
  }

  ul {
    margin-left: -10px;
  }

  li {
    border-right: 1px solid $primary-color;
    padding: 0 10px;
  }

  li:last-child {
    border-right: none;
  }

  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  @keyframes slide-in {
    0% {
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100%);
    }
  }

  @-webkit-keyframes slide-in {
    0% {
      -webkit-filter: blur(5px);
      -webkit-transform: translateX(-100%);
    }
  }
}
