@import '../../../node_modules/bootstrap/scss/bootstrap';

$primary-color: #3626a7;
$secondary-color: #caff8a;
$text-color: #f5f5f5;

$enable-responsive-font-size: true;
$font-size-base: 1rem;

.bg-left {
  background-color: $primary-color;
  color: $text-color;

  @include font-size(1.5rem);

  h6 {
    @include font-size(2rem);
  }

  h1 {
    @include font-size(6rem);

    z-index: 10;
    span {
      color: $secondary-color;
    }
  }

  .animated-typing {
    @include font-size(2rem);
  }

  p {
    max-width: 30rem;
    // max-width: 500px;
  }
}
.bg-right {
  background-color: $secondary-color;
}
.profile-photo {
  position: relative;
  margin-left: -45%;
  top: 25%;
  width: 80%;
  border-radius: 16px;
  border: 5px solid black;
  box-shadow: 30px 30px 0 -4px $primary-color, 30px 30px 0 0 #fff;
}

a {
  color: $text-color;
}

.vector-left {
  top: 60px;
  right: 15%;
  width: 50%;
}

.vector-right {
  top: 100px;
  right: 5%;
}
