$primary-color: #3626a7;
$secondary-color: #caff8a;
$text-color: #f5f5f5;

.experience {
  background-color: $primary-color;
  font-size: 1.15rem;

  h3 {
    font-size: 4rem;

    span {
      color: $secondary-color;
    }
  }

  color: $text-color;

  span {
    color: $secondary-color;
    font-weight: bold;
  }

  img {
    max-width: 100%;
    margin: 10px;
  }

  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  @keyframes slide-in {
    0% {
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100%);
    }
  }

  @-webkit-keyframes slide-in {
    0% {
      -webkit-filter: blur(5px);
      -webkit-transform: translateX(-100%);
    }
  }
}
